@import '../../../../scss/theme-bootstrap';

.gnav-offer {
  overflow: hidden;
  width: auto;
  @if is_2020_nav_redesign() {
    line-height: normal;
    text-align: center;
    @include breakpoint($landscape-up) {
      line-height: 2.3;
      text-align: right;
    }
  }
  @if is_cr20_desktop_nav_refresh() {
    @include breakpoint($landscape-up) {
      line-height: normal;
    }
  } @else {
    line-height: 2.3;
    text-align: right;
  }
  &__text {
    display: inline;
    font-size: 15px;
    line-height: 1;
    p {
      display: inline;
      margin: 0;
      font-size: 15px;
      line-height: 1;
    }
  }
  a {
    font-size: 15px;
  }
}
